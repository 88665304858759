import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './ErrorPage';

export default function ReactErrorBoundary(props: PropsWithChildren) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={() => {
        window.location.reload();
      }}>
      {props.children}
    </ErrorBoundary>
  );
}
