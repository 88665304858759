import { useQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { SideNavigationQuery } from '../components/Navigation/side-navigation-query.gql';

export const Overview = () => {
  const { data } = useQuery(SideNavigationQuery);
  let redirectUrl;
  if (data && Object.keys(data).length !== 0) {
  const { item } = data;
  const { EmployerUrlRedirect } = item;
  redirectUrl= EmployerUrlRedirect;
  }
  return <Navigate to={redirectUrl?.value} />;
};
