import { ConfigProperties } from './config';

export const PreConfig: ConfigProperties = {
  SITECORE_API_KEY: '{8BDE6ECE-4E18-4807-A807-CE328E998EF5}',
  GRAPHQL_ENDPOINT: 'https://pre.cd.wc.vitality.co.uk/sitecore/api/graph/edge',
  V4B_CDN_HOST: 'https://pre-v4b.cdn.vitality.co.uk',
  WSO2_SSO_HOST: 'https://pre.apis.vitality.co.uk/oauth2/oidcdiscovery',
  WSO2_CLIENT_ID: 'QPYCWiLejREMySAbt7s7Q8tZ6Fsa',
  EMPLOYER_HUB: 'https://pre.employerhub.vitality.co.uk',
  SESSION_CHECKING_IS_OPTIONAL: true,
};
