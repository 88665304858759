//Grid class section
export const vdsGrid = 'vds-grid';
export const vdsTwoGrid = 'vds-grid__column vds-grid__column--md-2';
export const vdsThreeGrid = 'vds-grid__column vds-grid__column--md-3';
export const vdsNineGrid = 'vds-grid__column vds-grid__column--md-9';
export const vdsCustomGrid = 'vds-main-content__primary-container';
export const vdsRootGrid =
  'vds-main-content vds-main-content--negative vds-main-content--with-sidebar vds-main-content--mobile-full-width vds-main-content--light-grey-bg';

//Main content section
export const vdsMainConent = 'vds-main-content';
export const vdsMainConentMain = 'vds-main-content__main';
export const vdsMainConentInner = 'vds-main-content__inner';
export const vdsMainConentSidebar = 'vds-main-content__sidebar';
//Side navigation section
export const sideNavigation = 'vds-side-navigation';
export const sideNavigationInner = 'vds-side-navigation__inner';
export const sideNavigationContainer = 'vds-side-navigation__list-container';
export const sideNavigationScroll = 'vds-side-navigation__scroll-container';
export const sideNavigationList = 'vds-side-navigation__list';
export const sideNavigationIcon =
  'vds-side-navigation__list-item vds-side-navigation__list-item--icon';
export const sideNavigationActive = 'vds-side-navigation__list-item--active';
export const sideNavigationText = 'vds-side-navigation__link-text';
export const sideNavigationLink = 'vds-side-navigation__link';
//Font awesome section
export const DownloadFont = 'fa-solid fa-download';
export const HomeFont = 'fa-solid fa-home';
export const HeartFont = 'fa fa-heart';
export const LineFont = 'fa fa-line-chart';
export const StarFont = 'fa fa-star';

//Error Section loading with error message and button
export const vdsErr = 'vds-interstitial-page';
export const vdsErrInner = 'vds-interstitial-page__inner';
export const vdsErrHeader = 'vds-interstitial-page__header';
export const vdsErrContainer = 'vds-interstitial-page__header-logo-container';
export const vdsErrLogo = 'vds-interstitial-page__header-logo';

export const vdsErrMain = 'vds-interstitial-page__main';
export const vdsSpinnerSpace = 'vds-loading-spinner vds-padding-spacing-0';
export const vdsSpinnerContainer = 'vds-loading-spinner__container';
export const vdsSpinnerIcon = 'vds-loading-spinner__icon';

export const vdsCardStack = 'vds-wide-card vds-wide-card--stacked';
export const vdsCardContainer = 'vds-wide-card__text-container';
export const vdsCardText = 'vds-wide-card__text';
export const vdsCardHeader = 'vds-wide-card__heading';

//Button
export const vdsButton = 'vds-button';
