import { jwtDecode } from 'jwt-decode';

import { AuthContext, AuthContextProps } from 'react-oidc-context';
import { STRICT_TOKEN_KEY } from '../../routes/authentication/LoginPage';
import { WSOAuthProvider } from './WSOAuthProvider';
import { AccessToken, AuthData } from './tokenTypes';

export const ExistingAuthTokenProvider = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const externalToken = params.get('token');
  if (!externalToken) {
    return <WSOAuthProvider>{children}</WSOAuthProvider>;
  }

  const accessToken = jwtDecode<AccessToken>(externalToken);
  const { entity_id } = accessToken;
  if (!entity_id) {
    return <WSOAuthProvider>{children}</WSOAuthProvider>;
  }
  localStorage.setItem(STRICT_TOKEN_KEY, 'true');
  const externalUser: AuthData = {
    isAuthenticated: true,
    id_token: externalToken,
    access_token: externalToken,
    isLoading: false,
    user: {
      id_token:externalToken,
      profile: {
        entity_id: accessToken.entity_id,
      },
    },
  };

  const authContextProps = externalUser as AuthContextProps
  return (
    <AuthContext.Provider
   
      value={{
        ...authContextProps,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
