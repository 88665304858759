import { ApolloClient, DefaultOptions, InMemoryCache, PossibleTypesMap } from '@apollo/client';
import 'cross-fetch/polyfill';

/*
  INTROSPECTION DATA
  See https://www.apollographql.com/docs/react/recipes/fragment-matching.html
  This enables the Apollo cache to process fragments on interface types correctly.
  If this file does not exist, you may need to run the `jss graphql:update` script.
*/
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import introspectionQueryResultData from '../temp/GraphQLFragmentTypes.json';
import { config } from '../config/config';

export const GraphQLClientFactory = (endpoint: string) => {
  /* HTTP link selection: default to batched + APQ */
  const link = new BatchHttpLink({
    uri: endpoint,
    headers: {
      connection: 'keep-alive',
      sc_apikey: config.SITECORE_API_KEY,
      origin: config.V4B_CDN_HOST,
    },
  });

  const possibleTypes: PossibleTypesMap = {};

  introspectionQueryResultData.__schema.types.forEach(supertype => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(
        subtype => subtype.name,
      );
    } else {
      possibleTypes[supertype.name] = [];
    }
  });

  const cache = new InMemoryCache({
    possibleTypes,
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
    },
    query: {
      fetchPolicy: 'network-only', // Used for first execution
    },
  };

  return new ApolloClient({
    link,
    cache,
    defaultOptions,
  });
};

export default GraphQLClientFactory;
