export const Footer = props => {
  const footerProps = props?.data;
  const footerNavigation = footerProps?.footerNavigation.footerNavigationJson;
  return (
    <footer className="vds-primary-navigation-side__footer">
      <ul className="vds-primary-navigation-side__footer-list">
        {footerNavigation?.map(item => {
          return (
            <li className="vds-primary-navigation-side__footer-item" key={item.fields.id}>
              <a className="vds-primary-navigation-side__footer-link" href={item.fields.FooterLink.value.href} target="_blank">
                {item.fields.Name.value}
              </a>
            </li>
          );
        })}
      </ul>

      <div className="vds-primary-navigation-side__legal">
        <p>{footerProps?.copyRightInfo.value}</p>
      </div>
    </footer>
  );
};

export default Footer;
