import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../global/Loader.tsx';
import { config } from '../../config';

export const LoginPage = () => {
  const auth = useAuth();
  const state: LoginStateParams = useLocation().state;
  const navigate = useNavigate();

  if (auth.error) {
    throw auth.error;
  }

  const loginLoader = <Loader loadingTitle={'Logging in'} loadingSubtitle={'You will be redirected shortly...'} />;

  if (auth.isLoading) {
    return loginLoader;
  }

  if (!auth.isAuthenticated) {
    if (state?.returnTo) {
      sessionStorage.setItem(RETURN_PATH_KEY, state.returnTo.path);
      sessionStorage.setItem(RETURN_SEARCH_KEY, state.returnTo.search);
    }

    auth.signinRedirect();
    return loginLoader;
  } else {
    const returnPath = (sessionStorage.getItem(RETURN_PATH_KEY) ?? '/') + (sessionStorage.getItem(RETURN_SEARCH_KEY) ?? '');
    sessionStorage.removeItem(RETURN_PATH_KEY);
    sessionStorage.removeItem(RETURN_SEARCH_KEY);

    if (config.SESSION_CHECKING_IS_OPTIONAL) {
      auth
        .querySessionStatus()
        .then(() => {
          localStorage.setItem(STRICT_TOKEN_KEY, 'false');
        })
        .catch(reason => {
          if (reason.error_description === 'Authentication required') {
            localStorage.setItem(STRICT_TOKEN_KEY, 'true');
          } else {
            console.error(`Unexpected session status error after login:\n${JSON.stringify(reason)}`);
          }
        });
    }

    navigate(returnPath, { replace: true });
    return loginLoader;
  }
};

export const RETURN_PATH_KEY = 'returnTo.path';
export const RETURN_SEARCH_KEY = 'returnTo.search';
export const STRICT_TOKEN_KEY = 'StrictTokens';

export interface LoginStateParams {
  returnTo: {
    path: string;
    search: string;
  };
}
