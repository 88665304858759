export enum RouteConstants {
  HOME = '/dashboard',
  SUPPORT = '/support',
  PLAN = '/plan',
  MEMBERS='/plan/members',
  OVERVIEW = '/plan/plan-overview',
  EXPLORE = '/plan/explore',
  GUIDE = '/support/guide',
  TOOLKIT = '/support/toolkit',
  RESOURCE = '/support/resource',
}
