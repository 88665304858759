import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { themeChange } from 'theme-change';
import { router } from './router';

import { ApolloProvider } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { GraphQLClientFactory } from './lib';
import { config } from './config/config';
import { OidcAuthProvider } from './global/auth/OidcAuthProvider.tsx';

loadDevMessages();

loadErrorMessages();

const graphQLClient = GraphQLClientFactory(config.GRAPHQL_ENDPOINT);

export const AppWrapper = () => {
  useEffect(() => {
    themeChange(false);
    // 👆 false parameter is required for react project
  }, []);

  return (
    <ApolloProvider client={graphQLClient}>
      <OidcAuthProvider>
        <RouterProvider router={router} />
      </OidcAuthProvider>
    </ApolloProvider>
  );
};
