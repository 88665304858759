import { MouseEvent } from 'react';
import {
  vdsButton,
  vdsCardContainer,
  vdsCardHeader,
  vdsCardStack,
  vdsCardText,
  vdsErr,
  vdsErrInner,
  vdsErrMain,
  vdsSpinnerContainer,
  vdsSpinnerIcon,
  vdsSpinnerSpace,
} from '../constants/class-names';

type LoaderProps = {
  loadingTitle: string,
  loadingSubtitle: string,
  buttonText?: string,
  buttonAction?: (e: MouseEvent<HTMLButtonElement>) => void
};

export const Loader = (props: LoaderProps) => {
  return (
    <div className={vdsErr}>
      <div className={vdsErrInner}>
        <main className={vdsErrMain}>
          <div className={vdsCardStack}>
            <div className={vdsSpinnerSpace}>
              <div className={vdsSpinnerContainer}>
                <div className={vdsSpinnerIcon}></div>
              </div>
            </div>
            <div className={vdsCardContainer}>
              <div className={vdsCardText}>
                <h2 className={vdsCardHeader}>{props.loadingTitle}</h2>
                <p>{props.loadingSubtitle}</p>
              </div>
            </div>
            {props.buttonText && props.buttonAction && (
              <div>
                <button
                  className={vdsButton}
                  aria-label="Action button pink"
                  title="Action button pink"
                  onClick={props.buttonAction}>
                  {props.buttonText}
                </button>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Loader;
