import { LocalConfig } from './local';
import { PrdConfig } from './prd';
import { PreConfig } from './pre';
import { SitConfig } from './sit';
import { UatConfig } from './uat';

const host = window.location.host;

export interface ConfigProperties {
  SITECORE_API_KEY: string;
  GRAPHQL_ENDPOINT: string;
  V4B_CDN_HOST: string;
  WSO2_SSO_HOST: string;
  WSO2_CLIENT_ID: string;
  EMPLOYER_HUB: string;
  SESSION_CHECKING_IS_OPTIONAL: boolean;
}

let props: ConfigProperties;

if (/^prod-v4b.cdn.vitality.co.uk$/.exec(host)) {
  props = PrdConfig;
} else if (/\bpre\b/.exec(host)) {
  props = PreConfig;
} else if (/\buat\b/.exec(host)) {
  props = UatConfig;
} else if (/\bsit\b/.exec(host)) {
  props = SitConfig;
} else {
  props = LocalConfig;
}

export const config: ConfigProperties = props;
