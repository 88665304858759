import { buildApplicationRoutes } from '@workplace-connect/v4b-wc-pkg-federation';
import PlanSearchRoutes from 'external/plan-search/routes';
import PlanRoutes from 'external/plan/routes';
import SupportRoutes from 'external/support/routes';
import { RouteObject } from 'react-router-dom';
import { SideNavigation } from './components/Navigation/SideNavigation';
import { routeInitializerModuleFederation } from './federation-helpers/route-initializer-module-federation';
import { RequireAuth } from './global/auth/RequireAuth.tsx';
import ReactErrorBoundary from './global/error/ReactErrorBoundary';
import { LoginPage } from './routes/authentication/LoginPage.tsx';
import { LogoutPage } from './routes/authentication/LogoutPage.tsx';
import { Overview } from './routes/overview';

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <ReactErrorBoundary>
        <LoginPage />
      </ReactErrorBoundary>
    ),
  },
  {
    path: '/logout',
    element: (
      <ReactErrorBoundary>
        <LogoutPage />
      </ReactErrorBoundary>
    ),
  },
  {
    path: '/',
    element: (
      <ReactErrorBoundary>
        <RequireAuth>
          <SideNavigation />
        </RequireAuth>
      </ReactErrorBoundary>
    ),
    children: [
      {
        path: '/',
        element: <Overview />,
      },
      ...buildApplicationRoutes(
        SupportRoutes,
        'external/support',
        routeInitializerModuleFederation('/support/assets/remoteEntry.js')
      ),
      ...buildApplicationRoutes(
        PlanRoutes,
        'external/plan',
        routeInitializerModuleFederation('/plan/assets/remoteEntry.js')
      ),
      ...buildApplicationRoutes(
        PlanSearchRoutes,
        'external/plan-search',
        routeInitializerModuleFederation('/plan-search/assets/remoteEntry.js')
      ),
    ],
  },
];

export default routes;
