
import { ExistingAuthTokenProvider } from './ExistingAuthTokenProvider.tsx';

 export const OidcAuthProvider = ({ children }) => {
  return (
    <ExistingAuthTokenProvider >
      {children}
    </ExistingAuthTokenProvider>
  );
};

 
