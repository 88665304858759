import { ReactNode, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginStateParams, STRICT_TOKEN_KEY } from '../../routes/authentication/LoginPage.tsx';
import Loader from '../Loader.tsx';
import { config } from '../../config';

export const RequireAuth = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      navigate('/login', {
        state: {
          returnTo: {
            path: location.pathname,
            search: location.search,
          },
        } as LoginStateParams,
      });
    }
  }, [auth.isAuthenticated, auth.isLoading, location.pathname, location.search, navigate]);

  useEffect(() => {
    const strictTokenKey = localStorage.getItem(STRICT_TOKEN_KEY);
    if (auth.isAuthenticated && (!config.SESSION_CHECKING_IS_OPTIONAL || strictTokenKey === 'false')) {
      auth.querySessionStatus().catch(() => {
        console.warn('User session expired/invalidated on SSO.');
        auth.removeUser();
      });
    }
  }, [auth]);

  if (auth.isAuthenticated) {
    return children;
  }
  return <Loader loadingTitle={'Logging in'} loadingSubtitle={'You will be redirected shortly...'} />;
};
