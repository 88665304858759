import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { config } from '../../config/config.ts';

const oidcConfig: AuthProviderProps = {
  authority: config.WSO2_SSO_HOST,
  client_id: config.WSO2_CLIENT_ID,
  redirect_uri: `${window.location.origin}/login`,
  silent_redirect_uri: `${window.location.origin}/login`,
  post_logout_redirect_uri: `${window.location.origin}/logout`,
  response_type: 'code',
  scope: 'openid',
  filterProtocolClaims: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export const WSOAuthProvider = ({ children }) => {
  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};
