import {
  vdsButton,
  vdsCardContainer,
  vdsCardHeader,
  vdsCardStack,
  vdsCardText,
  vdsErr,
  vdsErrInner,
  vdsErrMain,
} from '../../constants/class-names';
import { FallbackProps } from 'react-error-boundary';

export const ErrorPage = (props: FallbackProps) => {
  return (
    <div className={vdsErr}>
      <div className={vdsErrInner}>
        <main className={vdsErrMain}>
          <div className={vdsCardStack}>
            <div className={vdsCardContainer}>
              <div className={vdsCardText}>
                <h2 className={vdsCardHeader}>{props.error.name ?? 'An Error Occurred'}</h2>
                <p>{props.error.message ?? 'Please try again...'}</p>
              </div>
            </div>
            {props.resetErrorBoundary && (
              <div>
                <button
                  className={vdsButton}
                  aria-label="Action button pink"
                  title="Action button pink"
                  onClick={props.resetErrorBoundary}>
                  Try Again
                </button>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ErrorPage;
