import React from 'react';
import ReactDOM from 'react-dom/client';
import 'vitality-design-system';
import 'vitality-design-system/dist/assets/css/main.css';
import { AppWrapper } from './app-wrapper.tsx';
import ReactErrorBoundary from './global/error/ReactErrorBoundary';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReactErrorBoundary>
      <AppWrapper />
    </ReactErrorBoundary>
  </React.StrictMode>
);
